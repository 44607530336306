import Form from 'react-bootstrap/Form';

function ApplyFilters(props) {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.applyFilters(props.tracks, e.target.badWordCount.value);
  };

  const yellow = { color: "yellow" }
  return (
    <div className="searchbar">
      <Form variant="dark" className="search-form" onSubmit={e => { handleSubmit(e) }}>
        <div className="input-group">
          <input variant="dark" type="number" className="form-control" name="badWordCount" defaultValue="" placeholder="Input number of bad words per song" required />
          <div className="input-group-append">
            <button variant="dark" className="btn btn-secondary" type="submit">
              Filter Tracks!
            </button>
          </div>
          <div id="progress-status" className="input-group-append">
            {props.isProcessing ? (
              <span style={yellow}>Processing lyrics...</span>)
              : "All tracks processed"
            }
          </div>
        </div>
      </Form>
    </div>
  );
}


export default ApplyFilters