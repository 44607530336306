import { useState, useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import useStickyState from "./useStickyState"
import PlaylistSearch from "./playlistSearch"
import SpotifyLogin from './spotifylogin'
import ShowPlaylists from './playlists'
import Tracks from './tracks'
import Logout from "./logout"
import ApplyFilters from './applyFilters'
import ShowFeaturedPlaylists from './spotifyFeaturedPlaylists'
import AllowNoLyrics from './allowNoLyrics'
import ShowUserPlaylists from './showUserPlaylists'
import UserPlaylists from './userPlaylists'
import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import SavePlaylist from './savePlaylist'
import AllowInstrumental from './instrumental'

function App() {
  // stickyState only works for strings, atm
  const [playlistSearchTerm, setPlaylistSearchTerm] = useState("", "playlistSearchTerm")
  const [playlists, setPlaylists] = useState("", "playlists")
  const [access_token, setAccessToken] = useState("", "access_token")
  const [name, setName] = useStickyState("", "name")
  const [email, setEmail] = useStickyState("", "email")
  const [pid, setPid] = useState("", "pid")
  const [playlistName, setPlaylistName] = useState("", "playlistName")
  const [failingTrackIds, setFailingTrackIds] = useState([])
  const [allowNoLyrics, setAllowNoLyrics] = useState(false, "allowNoLyrics")
  const [allowInstrumental, setAllowInstrumental] = useState(true, "allowInstrumental")
  const [userPlaylists, setUserPlaylists] = useState("", "userPlaylists")
  const [isProcessing, setIsProcessing] = useState(false)
  const [isError, setIsError] = useState(false)
  const [showFeatured, setShowFeatured] = useState(true)
  const [tracks, setTracks] = useState([])
  const tracksRef = useRef([])
  tracksRef.current = tracks
  const [processedLookup, setProcessedLookup] = useState({})
  const [processQueue, setProcessQueue] = useState([])
  const processQueueRef = useRef([])
  processQueueRef.current = processQueue

  // instantiate the Spotify Player passes props in object to webplayer.js
  //  isPaused: isPaused, curTrackId: curTrackId, 
  // const webplayer = WebPlayer({ access_token: access_token, isReady: isReady, setIsReady: setIsReady, setDeviceId: setDeviceId, setIsPaused: setIsPaused, setCurTrackId: setCurTrackId });

  // load the access token through Python's session if can
  // if (!access_token) {

  const getInfo = useCallback(() => {
    fetch(`/api?do=getInfo`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          console.log("access token data: ", data)
          setAccessToken(data.access_token)
          setName(data.name)
          // setEmail(data.email)
        } else {
          setAccessToken("")
          setName("")
          // setEmail("")
        }
      })
      .catch((err) => {
        console.log("access token ERROR: ", err)
      })
  }, [setName])

  useEffect(() => {
    getInfo()
  }, [getInfo])


  // get lyrics for a batch of tracks
  const callProcessTracks = useCallback((trackIds) => {
    fetch(`/api?do=processTracks`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ track_ids: trackIds })
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // store the processed data in the existing tracks
        // which also triggers the process queue
        setProcessedLookup(Object.fromEntries(data.map((t) => [t.id, t])))
      })
  }, [setProcessedLookup])

  // triggered on change to processedLookup which happens after lyrics loaded
  // grabs top of queue, processes, and sets rest back to queue
  useEffect(() => {
    if (processedLookup === {}) { return }

    // first, update the tracks with the new lyrics data
    const updatedTracks = []
    for (const track of tracksRef.current) {
      const processedTrack = processedLookup[track.id]
      if (processedTrack) {
        updatedTracks.push(processedTrack)
      } else {
        updatedTracks.push(track)
      }
    }
    if ((updatedTracks.length) > 0) {
      setTracks(updatedTracks)
    }

    // then update the processing queue
    const left = processQueueRef.current.length
    console.log("processQueue left ", left)
    if (left === 0) {
      // done for now, remove the isProcessing flag
      if (isProcessing) { setIsProcessing(false) }
      return
    }

    // update the processing queue
    const BATCH_SIZE = 1
    const nowTrackIds = left > BATCH_SIZE ? processQueueRef.current.slice(0, BATCH_SIZE) : processQueueRef.current
    const laterTrackIds = left > BATCH_SIZE ? processQueueRef.current.slice(BATCH_SIZE) : []
    setProcessQueue(laterTrackIds)

    // process next batch
    callProcessTracks(nowTrackIds)
  }, [setTracks, callProcessTracks, setProcessQueue, setIsProcessing, isProcessing, processedLookup])

  // from the grabbed tracks, send the first batch to process lyrics and add the rest to a queue
  const processTracks = useCallback((curTracks) => {
    console.log("in processTracks", curTracks.length)
    if (curTracks.length === 0) { return }

    // isolate the unprocessed tracks
    const unprocessedTracks = curTracks.filter(track => !track.processed)
    if (unprocessedTracks.length === 0) { return }
    const unprocessedTrackIds = unprocessedTracks.map(track => track.id)
    const nowTrackIds = unprocessedTrackIds.slice(0, 3)
    const laterTrackIds = unprocessedTrackIds.slice(3)

    // process first 5 tracks, pass the rest to the processing queue
    console.log("nowtrackids", nowTrackIds)
    console.log("latertrackids left", laterTrackIds.length)
    if (laterTrackIds.length > 0) {
      // keep any existing tracks in the queue first
      const newProcQueue = [...new Set([
        ...processQueueRef.current,
        ...laterTrackIds,
      ])]
      console.log("newproc", newProcQueue)
      setProcessQueue(newProcQueue)
    }
    // process the lyrics
    callProcessTracks(nowTrackIds)
  }, [callProcessTracks, setProcessQueue])

  const fetchPlaylists = useCallback((playlistSearchTerm) => {
    setPlaylistSearchTerm(playlistSearchTerm)
    setTracks([])
    setFailingTrackIds([])
    setUserPlaylists([])
    setPlaylists([])

    fetch(`/api?do=getPlaylists&term=${encodeURIComponent(playlistSearchTerm)}`)
      .then((res) => res.json())
      .then((res) => {
        console.log("fetchPlaylists res: ", res)
        setPlaylists(res)
        setShowFeatured(false)
        console.log("featured off 1")
      })
  }, [setPlaylistSearchTerm, setTracks, setPlaylists])

  const fetchUserPlaylists = useCallback(() => {
    setTracks([])
    setFailingTrackIds([])
    setPlaylists([])
    setUserPlaylists([])
    fetch(`/api?do=getUserPlaylists`)
      .then((res) => res.json())
      .then((res) => {
        console.log("fetchUserPlaylists res: ", res)
        setUserPlaylists(res)
        setShowFeatured(false)
        console.log("featured off 2")
      })
  }, [setUserPlaylists])

  const fetchTracks = useCallback((pid, playlistName) => {
    setPlaylistName(playlistName)
    setIsProcessing(true)
    setPid(pid)
    // clear current track list
    setTracks([])
    setFailingTrackIds([])
    fetch(`/api?do=getTracksOnly&pid=${encodeURIComponent(pid)}`)
      .then((res) => res.json())
      .then((res) => {
        console.log("fetchTracks res: ", res)
        setTracks(res)
        processTracks(res)
        setShowFeatured(false)
        console.log("featured off 3")
      })
  }, [setPlaylistName, setIsProcessing, setPid, setTracks, setFailingTrackIds, processTracks])

  const applyFilters = useCallback((tracks, allowedCount) => {
    const params = {
      track_ids: tracks.map((track) => track.id),
      allow_no_lyrics: allowNoLyrics,
      allow_instrumental: allowInstrumental,
      allowed_count: allowedCount,
    }
    fetch("/api?do=filterTracks",
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("applyFilters res: ", res)
        setFailingTrackIds(res)
      })
  }, [setFailingTrackIds, allowInstrumental, allowNoLyrics])


  const logoutUser = useCallback((email) => {
    fetch(`/api?do=logout&email=${encodeURIComponent(email)}`)
      .then(() => {
        console.log('logout attempt')
        setAccessToken("")
        setName("")
        setEmail("")
        setUserPlaylists([])
      })
      .catch((err) => {
        console.log("logoutUser ERROR: ", err)
      })
  }, [setAccessToken, setName, setEmail])

  return (
    <section className="page">
      <div id="container">
        {/* LEFT SIDEBAR ------ */}
        <div id="left-sidebar">
          {/* Logo */}
          <a className="link" href="/"><div id="logo-wrapper"><div id="logo">
            <span>SafeSound</span>
            <span>SafeSound</span>
          </div></div></a>

          {/* Search */}
          <PlaylistSearch fetchPlaylists={fetchPlaylists} />

          {access_token && (<ShowUserPlaylists fetchUserPlaylists={fetchUserPlaylists} />)}

          {/* User Playlists */}
          {userPlaylists.length ? (
            <UserPlaylists
              userPlaylists={userPlaylists}
              pid={pid}
              setPid={setPid}
              setPlaylistName={setPlaylistName}
              fetchTracks={fetchTracks}
            />
          ) : null}

          {/* Search Results Playlists */}
          {playlists.length ? (
            <ShowPlaylists
              playlists={playlists}
              pid={pid}
              setPid={setPid}
              setPlaylistName={setPlaylistName}
              setTracks={setTracks}
              fetchTracks={fetchTracks}
            />
          ) : null}
        </div>

        {/* CENTER COLUMN ------ */}
        <div id="main-block">

          {/* HEADER ------ */}
          <div id="header-block">
            {access_token ?
              <Logout name={name} logoutUser={logoutUser} /> :
              <SpotifyLogin logoutUser={logoutUser} />
            }
            <br /><br />
          </div>

          <div id="intro-block">
            Remove songs with explicit lyrics from Spotify playlists
          </div>

          <div id="center-block">
            <div id="filters">
              <div>
                <AllowNoLyrics
                  allowNoLyrics={allowNoLyrics}
                  setAllowNoLyrics={setAllowNoLyrics}
                />
                &nbsp; &nbsp; &nbsp;
                <AllowInstrumental
                  allowInstrumental={allowInstrumental}
                  setAllowInstrumental={setAllowInstrumental}
                />
              </div>
              {access_token && tracks ? <SavePlaylist
                tracks={tracks}
                failingTrackIds={failingTrackIds}
                access_token={access_token}
                username={name}
                playlistName={playlistName}
                pid={pid}
                setPid={setPid}
                setIsError={setIsError}
              /> : null}
              {tracks.length ? <ApplyFilters tracks={tracks} applyFilters={applyFilters} isProcessing={isProcessing} /> : null}
            </div>


            {/* Tracks */}
            {pid && playlistName ?
              <Tracks
                tracks={tracks}
                failingTrackIds={failingTrackIds}
                playlistName={playlistName}
                isProcessing={isProcessing}
              /> : null}

            {/* Featured Playlists */}
            {!showFeatured ? <ShowFeaturedPlaylists
              setPid={setPid}
              setPlaylistName={setPlaylistName}
              setTracks={setTracks}
              fetchTracks={fetchTracks}
            /> : <div class="showFeaturedLink" onClick={() => { setShowFeatured(true) }}>show featured</div>}
          </div>
        </div>
      </div >
    </section >
  )
}

ReactDOM.render(<App />, document.getElementById("app"))

export default App
